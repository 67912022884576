import sortArrayBy from 'utils/sortBy';

const handleResponse = ({
    promise,
    wrapper,
    sortBy = undefined,
    sortType = undefined,
    sortDesc = false,
    asArray = false,
} = {}) => {
    return promise
        .then(({ data }) => {
            let res = data?.data ? data.data : data;

            if (wrapper) {
                res = Array.isArray(res)
                    ? res.map(elem => wrapper(elem))
                    : wrapper(res);
            }

            if (sortBy) {
                res = sortArrayBy({
                    data: res,
                    attr: sortBy,
                    type: sortType,
                    desc: sortDesc,
                });
            }

            if (asArray) {
                res = Array.isArray(res) ? res : [res];
            }

            return res;
        })
        .catch(err => {
            if (err.response?.data?.error) throw new Error(err.response.data?.error);
            if (err.response?.data) throw new Error(err.response.data);
            if (err.message) throw new Error(err.message);
            throw new Error(err);
        });
};

export default handleResponse;
